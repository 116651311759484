import { graphql, navigate } from 'gatsby';

import DynamicZone from '../../../../components/dynamic-zone/dynamic-zone';
import FloatingButton from '../../../../components/floating-button/floating-button';
import Layout from '../../../../components/layout';
import React from 'react';

const TailoredPaymentSolutionsPage = (props) => {
    const components = props.data.cms.tailoredPaymentSolutionsPageTemplate?.components || null;

    if (!components) {
        if (typeof window !== `undefined`) {
            navigate(`${props.pageContext.locale === `pl` ? `/pl` : ``}/404`);
        }
        return null;
    }

    return (
        <Layout location={props.location} locale={props.pageContext.locale}>
            <FloatingButton />
            <DynamicZone components={components} />
        </Layout>
    );
};

export default TailoredPaymentSolutionsPage;

export const query = graphql`
query ($locale: String!){
  cms {
    tailoredPaymentSolutionsPageTemplate(locale: $locale) {
      components {
        ... on STRAPI_ComponentComponentsHorizontalScroll {
          __typename
          HorizontalScrollBenefits {
            title
            subtitle
            image {
              url
            }
          }
          hasMobileGrayBackground
          horizontalScrollColor
          sectionTitle {
            ytLink
            style
            tailwindClasses
            title
            description
          }
          imagesTailwindClasses
          topPusherHeight
          topPusherHeightMobile
          mobileCardTailwindClasses
        }
        ... on STRAPI_ComponentComponentsHeader {
          __typename
          title
          textBoxCustomClasses
          headerSubtitle: subtitle
          primaryImage {
            url
          }
          mobileContentOffset
          contentOffset
          backgroundImageHeight
          backgroundImage {
            url
          }
        }
        ... on STRAPI_ComponentHorizontalTabsHorizontalTabs {
          __typename
          topPusherHeightMobile
          topPusherHeight
          tabs {
            type
            title
            subtitle
            labelImage {
              url
            }
            fullWidthImageItemsDirection
            imagesTailwindClasses
            labelColour
            label
            isLabelImageVisibleOnDesktop
            imagesTitles
            imagesMetadata
            scrollColor
            images {
              url
            }
          }
          tabHeight
          sectionTitle {
            tailwindClasses
            ytLink
            title
            style
            description
          }
        }
        ... on STRAPI_ComponentComponentsOffsetImageWithList {
          __typename
          topPusherHeightMobile
          topPusherHeight
          title
          offsetImageSubtitle: subtitle
          setComponentPadding
          mobileImageHeight
          imageType
          imageOffset
          imageHeight
          image {
            url
          }
          hasLightBackground
          isImageVisibleOnMobile
          ImageWithText {
            image {
              url
            }
            content
          }
        }
        ... on STRAPI_ComponentSeoSeo {
          __typename
          seoDescription
          seoLink
          seoTitle
          seoImages {
            url
          }
        }
        ... on STRAPI_ComponentComponentsSingleImage {
          __typename
          image {
            url
          }
          topPusherHeight
          topPusherHeightMobile
          singleImageContent
        }
      }
    }
  }
}
`;
